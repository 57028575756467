import React from "react";
import styled from "@emotion/styled";

import { Container } from "../styles/elements";

import fb from "../img/social/facebook.svg";
import twitter from "../img/social/twitter.svg";
import instagram from "../img/social/instagram.svg";
import linkedin from "../img/social/linkedin.svg";
import pinterest from "../img/social/pinterest.svg";
import youtube from "../img/social/youtube.svg";

const StyledFooter = styled.footer`
  background-color: #38393a;
  padding: 40px;
`;

const FooterContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
`;

const FooterContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const FooterColumn = styled.div`
  flex-basis: 300px;
  flex-shrink: 0;
`;

const StyledCopyright = styled.span`
  font-size: 12px;
  color: #c9c7c8;
`;

const FooterHeader = styled.h3`
  color: #babcbe;
  font-size: 12px;
  font-weight: 700;
  line-height: 2;
  letter-spacing: 0.1em;
`;

const FooterItem = styled.div`
  margin-bottom: 10px;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
`;

const FooterSocial = styled.div`
  display: flex;
`;

const FooterSocialImg = styled.img`
  height: 20px;
  margin-right: 15px;
`;

export default function Footer() {
  return (
    <StyledFooter>
      <FooterContainer>
        <FooterContent>
          <FooterColumn>
            <FooterItem>
              <FooterHeader>ADDRESS</FooterHeader>
              <p>25 Dockside Drive, Suite 700</p>
              <p>Toronto, ON M5A 0B5 </p>View Map
            </FooterItem>
            <FooterItem>
              <FooterHeader>PHONE</FooterHeader>
              <p>1-877-932-6233 (Toll Free)</p>
              <p>1-647-428-2220 (International)</p>
            </FooterItem>
            <FooterItem>
              <FooterHeader>EMAIL</FooterHeader>info@ecobee.com
            </FooterItem>
          </FooterColumn>
          <FooterColumn>
            <FooterItem>
              <FooterHeader>SALES HOURS</FooterHeader>
              <p>Mon-Fri (9am - 5pm EST)</p>
            </FooterItem>
            <FooterItem>
              <FooterHeader>SUPPORT HOURS</FooterHeader>
              <p>Mon-Fri (8am - 12am EST)</p>
              <p>Sat/Sun (9am - 9pm EST)</p>
              <p>Canadian holidays (9am - 9pm EST)</p>
            </FooterItem>
          </FooterColumn>
          <FooterColumn>
            <FooterSocial>
              <FooterSocialImg src={fb} alt="Facebook" />
              <FooterSocialImg src={twitter} alt="Twitter" />
              <FooterSocialImg src={instagram} alt="Instagram" />
              <FooterSocialImg src={linkedin} alt="LinkedIn" />
              <FooterSocialImg src={youtube} alt="YouTube" />
              <FooterSocialImg src={pinterest} alt="Pinterest" />
            </FooterSocial>
          </FooterColumn>
        </FooterContent>
        <StyledCopyright>© 2021 ecobee. All rights reserved.</StyledCopyright>
      </FooterContainer>
    </StyledFooter>
  );
}
