import styled from "@emotion/styled";

export const Container = styled.div`
  width: 100%;
  margin: auto;
  @media (min-width: 1000px) {
    width: 900px;
  }
  @media (min-width: 1000px) {
    width: 1067px;
  }
`;
