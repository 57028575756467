import React from "react";
import { Link } from "gatsby";
import logo_wordmark from "../img/logo_wordmark.svg";
import styled from "@emotion/styled";

import { Container } from "../styles/elements";

const Nav = styled.nav`
  border-bottom: 2px solid #eee;
`;

const NavLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const NavTitle = styled.span`
  font-size: 18px;
  margin-left: 20px;
`;

const Navbar = () => {
  return (
    <Nav
      className="navbar is-transparent"
      role="navigation"
      aria-label="main-navigation"
    >
      <Container>
        <div className="navbar-brand">
          <NavLeft>
            <Link to="/" title="Logo">
              <img src={logo_wordmark} alt="ecobee" />
            </Link>
            <NavTitle>Engineering & Data</NavTitle>
          </NavLeft>
          <a href="https://ecobee.com" className="navbar-item">
            <span>Visit ecobee.com</span>
          </a>
        </div>
      </Container>
    </Nav>
  );
};

export default Navbar;
